import { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import LoadData from '../../components/Global/LoadData';

const ContactRequests = () => {

    const { Auth, setAuth, ActionsFiltred, FindActionPerId } = useContext(AuthContext);

    const navigate = useNavigate();


    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? searchParams.get('page') : '1';

    const [Word, setWord] = useState(searchParams.get('nom') ? searchParams.get('nom') : '')
    const [Tel, setTel] = useState(searchParams.get('tel') ? searchParams.get('tel') : '')
    const [Validvendeur, setValidvendeur] = useState(searchParams.get('validvendeur') ? searchParams.get('validvendeur') : 'vide')
    const [SubCategory, setSubCategory] = useState(searchParams.get('SubCategory') ? searchParams.get('SubCategory') : 'vide')

    const [Wilaya, setWilaya] = useState(searchParams.get('wilaya') ? searchParams.get('wilaya') : 'all')

    const [StatusType, setStatusType] = useState(searchParams.get('status_type') ? searchParams.get('status_type') : 'vide')
    const [Date_start, setDate_start] = useState(searchParams.get('date_start') ? searchParams.get('date_start') : '')

    const [Categorie, setCategorie] = useState(searchParams.get('cat') ? searchParams.get('cat') : 'all')


    const [secteuActivité, setsecteuActivité] = useState(searchParams.get('secteur') ? searchParams.get('secteur') : 'vide');

    const [ModePayment, setModePayment] = useState(searchParams.get('payment') ? searchParams.get('payment') : 'vide');



    const [UrlPagination, setUrlPagination] = useState('')




    const [ListVentes, setListVentes] = useState([])

    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    const [TotalData, setTotalData] = useState('')

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            const date_start = Date_start !== '' ? '&date_start=' + Date_start : '';
            const status_type = StatusType !== '' ? '&status_type=' + StatusType : '';

            const keyword = Word.trim() !== '' ? '&nom=' + Word : '';
            const tel_vendeur = Tel.trim() !== '' ? '&tel=' + Tel : '';
            const valid_vendeur = Validvendeur == "119" || Validvendeur == "37" ? '&validvendeur=' + Validvendeur : '';
            const SubCategoryValue = valid_vendeur && onlyNumbers(SubCategory) ? '&SubCategory=' + SubCategory : '';

            const ModePaymentValue = ModePayment == "1" || ModePayment == "2" ? '&payment=' + ModePayment : '';



            const wilaya_boutique = Wilaya?.toString().trim() !== '' && Wilaya?.toString().trim() !== 'all' ? '&wilaya=' + Wilaya : '';

            const Cat_Project = Categorie?.toString().trim() !== '' && Categorie?.toString().trim() !== 'all' ? '&cat=' + Categorie : '';

            const Secteur_Activité = secteuActivité?.toString()?.trim() && secteuActivité?.toString()?.trim() !== 'all' ? '&secteur=' + secteuActivité : '';



            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/reclamations/contact?page=${page}${Cat_Project}${valid_vendeur}${SubCategoryValue}${keyword}${tel_vendeur}${status_type}${date_start}${Secteur_Activité}${ModePaymentValue}${wilaya_boutique}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])
                setTotalData(response?.['TotalData'])


                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    console.log(ListVentes);



    useEffect(() => {


        // if (Auth) {
        //   console.log('hi!');

        // }else{
        //   console.log('no!'); 
        //   return navigate("/");
        // }


    }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    async function removeVendeur(id) {

        Swal.fire({
            title: 'Êtes-vous sûr',
            text: "Voulez-vous vraiment supprimer cette demande ?!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/reclamations/delete_contact/${id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'opération ne s'est pas terminée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {

                        const updatefListVentes = ListVentes.filter(item => item.id !== id);
                        setListVentes(updatefListVentes);

                        await Swal.fire(
                            'Supprimée!',
                            'Cette demande a été supprimé',
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                }
            },
        })

    }

    async function validVendeur(id, etat) {


        Swal.fire({
            title: 'Êtes-vous sûr',
            text: etat == 1 ? "Êtes-vous sûr d'activer ce vendeur ?" : "Êtes-vous sûr de désactiver ce vendeur ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendeur/valid_vendeur/${id}/${etat}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'opération ne s'est pas terminée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {
                        const updatefListVentes = ListVentes.map(obj =>
                            obj.id_vendeur == id ? { ...obj, active: etat } : obj
                        );
                        setListVentes(updatefListVentes);

                        await Swal.fire(
                            etat == 1 ? 'Activé!' : 'Désactiver!',
                            etat == 1 ? 'Ce vendeur a été activé' : 'Ce vendeur a été désactivé',
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                }
            },
        })
    }


    useEffect(() => {

        const date_start = Date_start !== '' ? '&date_start=' + Date_start : '';
        const status_type = StatusType !== '' ? '&status_type=' + StatusType : '';

        const keyword = Word.trim() !== '' ? '&nom=' + Word : '';
        const tel_vendeur = Tel.trim() !== '' ? '&tel=' + Tel : '';
        const valid_vendeur = Validvendeur == '119' || Validvendeur == '37' ? '&validvendeur=' + Validvendeur : '';
        const SubCategoryValue = valid_vendeur && onlyNumbers(SubCategory) ? '&SubCategory=' + SubCategory : '';
        const ModePaymentValue = ModePayment == "1" || ModePayment == "2" ? '&payment=' + ModePayment : '';

        const wilaya_boutique = Wilaya?.toString()?.trim() !== '' && Wilaya?.toString()?.trim() !== 'all' ? '&wilaya=' + Wilaya : '';

        const Cat_Project = Categorie?.toString()?.trim() !== '' && Categorie?.toString()?.trim() !== 'all' ? '&cat=' + Categorie : '';

        const Secteur_Activité = secteuActivité?.toString()?.trim() && secteuActivité?.toString()?.trim() !== 'all' ? '&secteur=' + secteuActivité : '';


        let url = keyword + tel_vendeur + valid_vendeur + SubCategoryValue + ModePaymentValue + wilaya_boutique + date_start + status_type + Cat_Project + Secteur_Activité

        setUrlPagination(url)



    }, [Word, Tel, Validvendeur, SubCategory,ModePayment, Wilaya, Date_start, StatusType, Categorie, secteuActivité])

    function SearchQUERY() {
        const fetchData = async () => {
            setLoading(true);

            const keyword = Word.trim() !== '' ? '&nom=' + Word : '';
            const tel_vendeur = Tel.trim() !== '' ? '&tel=' + Tel : '';
            const valid_vendeur = Validvendeur == "119" || Validvendeur == "37" ? '&validvendeur=' + Validvendeur : '';
            const SubCategoryValue = valid_vendeur && onlyNumbers(SubCategory) ? '&SubCategory=' + SubCategory : '';
            const ModePaymentValue = ModePayment == "1" || ModePayment == "2" ? '&payment=' + ModePayment : '';

            const wilaya_boutique = Wilaya?.toString().trim() !== '' && Wilaya?.toString().trim() !== 'all' ? '&wilaya=' + Wilaya : '';

            const date_start = Date_start !== '' ? '&date_start=' + Date_start : '';
            const status_type = StatusType !== '' ? '&status_type=' + StatusType : '';

            const Cat_Project = Categorie?.toString().trim() !== '' && Categorie?.toString().trim() !== 'all' ? '&cat=' + Categorie : '';

            const Secteur_Activité = secteuActivité?.toString()?.trim() && secteuActivité?.toString()?.trim() !== 'all' ? '&secteur=' + secteuActivité : '';


            setSearchParams({ page: 1, ...(Word.trim() !== '' && { nom: Word }), ...(Tel.trim() !== '' && { tel: Tel }), ...(Date_start !== '' && { date_start: Date_start }), ...(StatusType !== '' && { status_type: StatusType }), ...((Validvendeur == "119" || Validvendeur == "37") && { validvendeur: Validvendeur }), ...((valid_vendeur && onlyNumbers(SubCategory)) && { SubCategory: SubCategory }), ...((ModePayment == "1" || ModePayment == "2") && { payment: ModePayment }), ...((Wilaya.trim() !== '' && Wilaya.trim() !== 'all') && { wilaya: Wilaya }), ...((Categorie.trim() !== '' && Categorie.trim() !== 'all') && { cat: Categorie }), ...((secteuActivité?.toString()?.trim() && secteuActivité?.toString()?.trim() !== 'all') && { secteur: secteuActivité }) })
            // setSearchParams({page : 1,nomdeboutique: NomBoutique.trim() !== '' ?  NomBoutique :  ''})

            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/reclamations/contact?page=${1}${keyword}${Cat_Project}${tel_vendeur}${date_start}${status_type}${valid_vendeur}${SubCategoryValue}${ModePaymentValue}${wilaya_boutique}${Secteur_Activité}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])
                setTotalData(response?.['TotalData'])


                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }
        fetchData();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            SearchQUERY();
        }
    };


    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    const Lang = 'fr';


    const secteuActivitéList = [
        { 'fr': 'Salarié (Public)', 'ar': '(قطاع عمومي) موظف', 'value': "1" },
        { 'fr': 'Salarié (Privé)', 'ar': '(قطاع خاص) موظف', 'value': "2" },
        { 'fr': 'Retraité ', 'ar': 'متقاعد', 'value': "5" },
        { 'fr': 'Commerçant', 'ar': 'تاجر', 'value': "3" },
        { 'fr': 'Chômeur', 'ar': 'بدون عمل', 'value': "4" },

        // { "ar": { "value": "أثاث", 'text': "أثاث" }, "fr": { "value": "Meubles", 'text': "Meubles" } },
        // { "ar": { "value": "أجهزة كهرومنزلية وأثاث", 'text': "أجهزة كهرومنزلية وأثاث" }, "fr": { "value": "Électroménager et Meubles", 'text': "Électroménager et Meubles" } },
        // { "ar": { "value": "أخرى", 'text': "أخرى" }, "fr": { "value": "Autre", 'text': "Autre" } },
    ];

    function FindsecteurPerId(id) {

        let action_name = secteuActivitéList?.find(o => o.value == id)

        if (action_name) {
            return action_name?.fr;

        } else {
            return '-'
        }


    }


    const [Category, setCategory] = useState([])
    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/agents`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                // console.log(response[0]);
                setCategory(response['data']);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);

    const isInitialMountCat = useRef(true);
    const [SousCategoriesList, setSousCategoriesList] = useState([])

    useEffect(() => {
        setSousCategoriesList([])

        const GetSousCategories = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/allCategorie?unicnom=Dar-Taksit`);
                console.log(response);

                const datamenu = response?.[0];
                // console.log(DartaksitMENU)    
        
                // for (let index = 0; index < datamenu.length; index++) {
                //   const {data: response} = await axios.get(`https://opr.diardzair.com.dz/ws/categorie?unicnom=${datamenu[index].unicnom}`);
                //   setsubMenu(v => [...v , ...response[0]])
                // }
        
                // const result = datamenu.filter(word => word.profondeur == 2);
                const result = datamenu?.filter(word => word?.profondeur == 3 && word?.idParent == Validvendeur);


                setSousCategoriesList(result)
                if (isInitialMountCat.current) {
                    isInitialMountCat.current = false;
                } else {
                    setSubCategory('')
                }
            } catch (error) {
                console.error(error.message);
            }
        }

        if (onlyNumbers(Validvendeur)) {
            GetSousCategories();
        }


    }, [Validvendeur])



//     useEffect(() => {
//         var query = '';
//         const fetchData = async (hash) => {
//             try {
//               const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/produit?hash=${hash}`);
//             //   console.log(response);
             
//             // if (response?.sim?.parent?.id == "37") {
//                 query += `WHEN hash = ${hash} THEN ${response?.sim?.categorie?.id} `;
//                 console.log(query);
                
//             // }
      
      
      
//             } catch (error) {
              
//               // console.error(error.message);
//             }
//             // setLoading(false);
//           }
    
//         const Producte = [
//             {"id":"51756","name":"Adel berrouba","email":null,"mobile":"0667650345","message":null,"secteur":"2","hash":"07b87044316f2d9cbe7d3f5718dcba0b","product":"Hotte Aspirante Beko 60Cm-Inox- Décorative - Box-BHCB66741BBHA","category":"37","subcategory":null,"payment_methode":"2","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"31","date":"2024-08-31 22:16:27"},
// {"id":"47741","name":"Ghadaouia abd elbaki","email":null,"mobile":"0671936636","message":null,"secteur":"1","hash":"1cc75c7bd1f20a6d513e5f9fb412143a","product":"TABLE DE CUISSON ROSIERES  60CM 04 FEUX INOX RHGX6ALG-2","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"36","date":"2024-08-12 15:25:47"},
// {"id":"45230","name":"Gherib hafida","email":null,"mobile":"0699026395","message":null,"secteur":"5","hash":"895736b346a29dd7a74a914f217aa3b1","product":"TABLE DE CUISSON  ROSIERES 75CM 05 FEUX  EN FONTE INOX RHG7XALG-2","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"37","date":"2024-08-07 07:06:03"},
// {"id":"42677","name":"Kaddache ameur","email":null,"mobile":"0699488870","message":null,"secteur":"1","hash":"9ea680aba68121822384d1ca8f17f73d","product":"téléviseur led 55g5010 UHD google tv gris IRIS","category":"37","subcategory":null,"payment_methode":"1","Status_User":"1","date_action":"2024-07-29 21:32:28","admin_name_action":"afroune sara","admin_id_action":"13","agent_id":"26","date":"2024-07-29 15:43:47"},
// {"id":"42664","name":"FAKKAR REDHWANE","email":null,"mobile":"0671766577","message":null,"secteur":"5","hash":"ab0a8eec50ebbb3b438e27f7a3d590b9","product":"Téléviseur Samsung LED 65\" UHD S8 SMART UA65BU8000U","category":"37","subcategory":null,"payment_methode":"1","Status_User":"4","date_action":"2024-07-29 18:35:45","admin_name_action":"doriaab","admin_id_action":"29","agent_id":"18","date":"2024-07-29 15:15:47"},
// {"id":"42411","name":"mounira DJOUDi","email":null,"mobile":"0542914005","message":null,"secteur":"2","hash":"08c8ab43be540543b8333c224fa599e4","product":"Téléviseur Samsung LED 58\" UHD S7 SMART UA58CU7000U","category":"37","subcategory":null,"payment_methode":"2","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"31","date":"2024-07-28 17:53:29"},
// {"id":"42384","name":"sofiane bouchakour","email":null,"mobile":"0554545302","message":null,"secteur":"2","hash":"9d59dd9dfae68b5bbe1ca64ce1e6c47f","product":"Lave Linge Samsung Front 10,5 Kg+7 add wash séchante 1400 Tr Inox WD10T654DBN","category":"37","subcategory":null,"payment_methode":"2","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"31","date":"2024-07-28 15:54:39"},
// {"id":"38079","name":"Kerfah Abdelhak","email":null,"mobile":"0557346087","message":null,"secteur":"1","hash":"d9ebb06e68af1b8c6348afad29fbc0b5","product":"Lave Linge Samsung Front 8 Kg 1400 Tr Inox WW80TA046AX","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"12","date":"2024-07-19 00:54:53"},
// {"id":"37650","name":"Temam rachida","email":null,"mobile":"0553162370","message":null,"secteur":"1","hash":"57eb513e2e29f7f06a3677576d9d4a6f","product":"Réfrigérateur Samsung 2 portes 590L A+ Inox RT59K6231S8","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"31","date":"2024-07-18 13:13:57"},
// {"id":"35842","name":"Toureche younes","email":null,"mobile":"0664612520","message":null,"secteur":"1","hash":"49026a97dd5f98bcb23410166219a932","product":"Radiateur 12 éléments EL HIDHAB","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"30","date":"2024-07-15 18:48:34"},
// {"id":"35602","name":"oobauda","email":null,"mobile":"0659935219","message":null,"secteur":"1","hash":"3b7e686b7625408b2a93ef0bfd0ca0e3","product":"Hotte casquette BEKO 60 cm\/Filtre à Charbon\/Inox CFB6432XHA","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-07-15 13:24:15"},
// {"id":"25423","name":"Ouriach yamina","email":null,"mobile":"0668698253","message":null,"secteur":"1","hash":"8c2277fb513a88f12742a59a8cb527a3","product":"MICRO ONDE ROSIERES ENC 28L NOIR AVEC GRILL RMG28-1PN-EUR","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-06-23 13:44:19"},
// {"id":"19050","name":"brahimhenchirigmailcom","email":null,"mobile":"0660301136","message":null,"secteur":"1","hash":"40168ff04dc6a452c0e5cd36549d0d06","product":"FOUR ENCASTRABLE ROSIERES 60CM A GAZ 65L AVEC AFF CATALYSE NOIR RGGGFC2TN-MAG","category":"37","subcategory":null,"payment_methode":"2","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-06-09 20:53:01"},
// {"id":"18806","name":"Hamioud rabie","email":null,"mobile":"0666668920","message":null,"secteur":"1","hash":"c59da687c6a4f2ef2ad053bba6597cae","product":"FOUR ENCASTRABLE ROSIERES 60CM A GAZ 65L AVEC AFFICHEUR CATALYSE INOX RGGGFC2T-MAG-1","category":"37","subcategory":null,"payment_methode":"1","Status_User":"9","date_action":"2024-06-23 09:24:27","admin_name_action":"doriaab","admin_id_action":"29","agent_id":"29","date":"2024-06-08 22:25:57"},
// {"id":"14753","name":"Tarek larekem","email":null,"mobile":"0540217797","message":null,"secteur":"1","hash":"507c953aa6d711fd12bbbf654b6af23d","product":"FOUR ENC GAZ\/ELEC INOX BIGT22101X","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-05-30 12:16:21"},
// {"id":"13069","name":"Hammad rabah","email":null,"mobile":"0550639927","message":null,"secteur":"5","hash":"7b6c6fd0d7f721b633c9697b01e989d9","product":"PACK Confort pour F3 (chaudière murale à gaz 24 kw + 3 radiateurs ALHIDAB sans installation )","category":"37","subcategory":null,"payment_methode":"2","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-05-27 17:01:40"},
// {"id":"11980","name":"Zerrouk nourredine","email":null,"mobile":"0550194442","message":null,"secteur":"2","hash":"9bd98d9a16739a2296fd1b0df8dc7551","product":"Refrigerateur 2 portes 400 L INOX - rt38k5532s8","category":"37","subcategory":null,"payment_methode":"2","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"18","date":"2024-05-25 23:58:30"},
// {"id":"11183","name":"Belabbas kamel","email":null,"mobile":"0656612043","message":null,"secteur":"1","hash":"b85d876e4957a77789d5488b4c0efc40","product":"PACK Confort pour F4 (chaudière murale à gaz 24 kw + 4 radiateurs ALHIDAB avec installation )","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"26","date":"2024-05-24 10:14:26"},
// {"id":"7562","name":"yahi nacera","email":null,"mobile":"0778697318","message":null,"secteur":"1","hash":"049b2e49bca86f82696a33699bf41491","product":"Lave linge SAMSUNG Top 7 KG 700 Tr gris -wa70h4000sg","category":"37","subcategory":null,"payment_methode":"1","Status_User":"10","date_action":"2024-05-12 20:24:33","admin_name_action":"ouamara kenza","admin_id_action":"15","agent_id":"11","date":"2024-05-12 13:33:05"},
// {"id":"7073","name":"Hamidi amine","email":null,"mobile":"0552964813","message":null,"secteur":"1","hash":"917824f644ee5b714fcd8825bb2100d4","product":"Hotte Murale Géant GN-CLSCO60-I","category":"37","subcategory":null,"payment_methode":"1","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"26","date":"2024-05-11 12:33:49"},
// {"id":"6500","name":"Belgacom Aicha","email":null,"mobile":"0655765827","message":null,"secteur":"1","hash":"926391502ef5d3948862a38ceae66e96","product":"FOUR ENCASTRABLE THOMSON GAS\/ELEC VENTILLE CATALYSEUR AFFICHEUR -Noir-TFE-DGE60-BL","category":"37","subcategory":null,"payment_methode":"1","Status_User":"9","date_action":"2024-05-15 18:37:14","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"11","date":"2024-05-10 07:33:56"},
// {"id":"6316","name":"Zerniz hakim","email":null,"mobile":"0699912814","message":null,"secteur":"1","hash":"f47f9318105eb4e833dbb470bea248b7","product":"Hotte casquette BEKO 60cm, Filtre à Charbon, Inox","category":"37","subcategory":null,"payment_methode":"1","Status_User":"9","date_action":"2024-05-15 18:52:51","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"11","date":"2024-05-09 13:45:55"},
// {"id":"5575","name":"Boudarebala oum djilali","email":null,"mobile":"0779741786","message":null,"secteur":"1","hash":"c67aa22fd75204cab37236a3b5af5018","product":"Table de Cuisson BEKO 75cm 5feux, Wok, Allumage intégré, Thermocouple, Grilles en fonte, Verre Noir - HILWR75225S","category":"37","subcategory":null,"payment_methode":"2","Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-05-05 17:07:36"},
// {"id":"5388","name":"cscdimagmailcom","email":null,"mobile":"0792754270","message":null,"secteur":"2","hash":"a17e0dde93f7504d537360480786a5c4","product":"Réfrigérateur samsung 400L A+ Inox deux portes RT40K5012S8","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"24","date":"2024-05-04 14:38:32"},
// {"id":"5352","name":"Sbaihia   Abdelkrim","email":null,"mobile":"0696151581","message":null,"secteur":"1","hash":"07a5b211fa332a1d36331b79a4d878bc","product":"Réfrigérateur 410L Cristor Gris RFDK410G","category":"37","subcategory":null,"payment_methode":null,"Status_User":"12","date_action":"2024-05-11 08:17:14","admin_name_action":"khadidja terkmani","admin_id_action":"24","agent_id":"24","date":"2024-05-04 11:28:08"},
// {"id":"5114","name":"Chikh salah","email":null,"mobile":"0662196141","message":null,"secteur":"2","hash":"c4f4839ec38c3aff2e56b78ac3891d15","product":"Four encastrable GAS\/GAS-Ventilé-Catalyseur-Afficheur-INOX- TFE-AGG60-IX","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"28","date":"2024-05-03 12:52:38"},
// {"id":"4832","name":"Ziane noureddine","email":null,"mobile":"0660370627","message":null,"secteur":"5","hash":"88a9e8fb8e67c43c9f1726b4ed09bf64","product":"CUISINIERE MAXWELL 60*60 BLANCHE  MAX-CKF60B","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-05-08 11:24:36","admin_name_action":"rym kadik","admin_id_action":"26","agent_id":"22","date":"2024-05-02 18:20:04"},
// {"id":"4765","name":"Aroudj Lyes","email":null,"mobile":"0664578760","message":null,"secteur":"3","hash":"5a685534f4349718197ed6cb63ebdc98","product":"LAVE LINGE SAMSUNG 7 KG 1200 TR INOX - WW70T4020CX","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"18","date":"2024-05-02 15:01:40"},
// {"id":"4606","name":"Youmen loubna","email":null,"mobile":"0663837005","message":null,"secteur":"5","hash":"789a0dec143275c62a9c44832c796a9d","product":"Hotte Aspirante Beko 60Cm-Noir laqué Plan incliné - BHCA66741BBHA","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-05-06 13:38:14","admin_name_action":"rym kadik","admin_id_action":"26","agent_id":"26","date":"2024-05-01 22:32:25"},
// {"id":"4603","name":"Youmen loubna","email":null,"mobile":"0663837005","message":null,"secteur":"5","hash":"f4da5108579d89be0db45d14247445d8","product":"Table de Cuisson BEKO 75cm 5feux, Wok, Allumage intégré, Thermocouple, Grilles en Fonte, Inox -HIAW75225SX","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-07-26 20:23:58","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"25","date":"2024-05-01 22:27:17"},
// {"id":"4475","name":"Aliouche narimane","email":null,"mobile":"0671232201","message":null,"secteur":"1","hash":"712ccbbd59cd93af8d49aa1bffa84e62","product":"COCOTTE MINUTE SEB PRECISION + 4.5L","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"11","date":"2024-05-01 16:19:10"},
// {"id":"4389","name":"Boukhelf aissa","email":null,"mobile":"0770676770","message":null,"secteur":"1","hash":"28e39c7986bb4b94dafadfbc28d838bd","product":"Plaque de cuisson LUNA 60*60 CM, Grille émaillée \/Surface Verre trempé Noir PANEL\/ FFD° HB-N60GF","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"26","date":"2024-05-01 12:38:11"},
// {"id":"4143","name":"Nasri brahim","email":null,"mobile":"0561835040","message":null,"secteur":"1","hash":"23c27f01255106b730eeaab46b024798","product":"Lave Vaisselle 13 Couverts INOX SAMSUNG DW60M5050FS\/FH","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"12","date":"2024-04-30 18:34:45"},
// {"id":"3638","name":"Adel moumen","email":null,"mobile":"0792926795","message":null,"secteur":"1","hash":"bb76aefbe9ede0198564061a6c3b959e","product":"Refrigerateur 320L Condor Blanc CRF-T36GH07","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"25","date":"2024-04-29 16:42:52"},
// {"id":"3623","name":"Benzahi mohamed bilal","email":null,"mobile":"0668307110","message":null,"secteur":"1","hash":"b02c361d6ce74e1f51cb458a560e091b","product":"Cuisiniere 4Fx 55Cm Cristor Rossa Blanc ROS464W","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"22","date":"2024-04-29 16:12:29"},
// {"id":"3448","name":"Hamdanilalmi","email":null,"mobile":"0699335232","message":null,"secteur":"1","hash":"fd3dd93135cb119d940a76232ca3d477","product":"PACK Confort pour F4 (chaudière murale à gaz 24 kw + 4 radiateurs ALHIDAB sans installation )","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"17","date":"2024-04-28 17:36:39"},
// {"id":"3447","name":"Hamdani Lalmi","email":null,"mobile":"0699335232","message":null,"secteur":"1","hash":"9d5fdd71a9ed038afdc0a87513d67213","product":"Congélateur THOMSON Armoire, class A, No frost, 5 Tiroirs +1 Rabat, 208L Blanc - TH-REF-FZ-208W","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-05-15 17:40:01","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"11","date":"2024-04-28 17:34:57"},
// {"id":"3216","name":"Karoun mohamed","email":null,"mobile":"0559088620","message":null,"secteur":"1","hash":"555ce2e4d9b204be08f8dea477c997de","product":"Table de Cuisson BEKO 75cm 5feux, Wok, Allumage intégré, Thermocouple, Grilles en Fonte, INOX - HILL75235S","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"23","date":"2024-04-27 10:06:35"},
// {"id":"3213","name":"Megaouib salim","email":null,"mobile":"0660503498","message":null,"secteur":"1","hash":"e1453d68fb864292defe62a5675e1174","product":"Réfrigérateur SAMSUNG 2 portes 490L A+ Inox RT49K5532S8","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"23","date":"2024-04-27 09:57:28"},
// {"id":"3190","name":"Oussama fetane","email":null,"mobile":"0541268177","message":null,"secteur":"2","hash":"7d1801c7d84c7259e46d33e2ba1964bc","product":"Four encastrable BEKO ELECTRIQUE 90L 60X60, Double Ventillateu, 4 VITRE, Afficheur, NOIR AVEC PROFIL INO","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"23","date":"2024-04-27 09:07:17"},
// {"id":"2830","name":"Aichoun djamel","email":null,"mobile":"0698996673","message":null,"secteur":"5","hash":"68fa1afc7a3434d2817971c9903b5b82","product":"Lave linge SAMSUNG Top 9 KG 700 Tr gris -WA90T5260BY","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-27 09:44:11","admin_name_action":"nesrine diardzair","admin_id_action":"25","agent_id":"25","date":"2024-04-26 08:32:05"},
// {"id":"2804","name":"Khainnar hasnaoui","email":null,"mobile":"0659432046","message":null,"secteur":"5","hash":"d54eddcaf45b2d7cb5af0d00a3cc480f","product":"Hotte Aspirante Beko 90Cm-Noir laqué Plan incliné-BHCA96741BBHA","category":"37","subcategory":null,"payment_methode":null,"Status_User":"3","date_action":"2024-04-28 09:39:00","admin_name_action":"farah dream","admin_id_action":"22","agent_id":"22","date":"2024-04-26 07:38:19"},
// {"id":"2802","name":"khainnar hasnaoui","email":null,"mobile":"0659432046","message":null,"secteur":"5","hash":"c7829fac2c054063fc875a75296c846e","product":"COCOTTE MINUTE SEB LA RECYCLEE 7.5L","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-05-02 14:03:21","admin_name_action":"nesrine diardzair","admin_id_action":"25","agent_id":"13","date":"2024-04-26 07:34:51"},
// {"id":"2590","name":"Messai ishak","email":null,"mobile":"0676705352","message":null,"secteur":"1","hash":"3763c92daad20004ce102d96d27f9c92","product":"CUISINIERETHOMSON 60*60 INOX VENTILE GAZ - ELECTRIC  CLASSICA","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-26 09:42:01","admin_name_action":"feriel kargas","admin_id_action":"24","agent_id":"24","date":"2024-04-25 19:24:35"},
// {"id":"2522","name":"Benaouf abdelghani","email":null,"mobile":"0663530871","message":null,"secteur":"2","hash":"12d72d9361ce314bad38f42f78f97ac0","product":"Table de Cuisson BEKO 60cm, 4 feux, Allumage intégré, Thermocouple, INOX - HIAG64223X","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-04-26 11:09:31","admin_name_action":"nesrine diardzair","admin_id_action":"25","agent_id":"25","date":"2024-04-25 17:11:12"},
// {"id":"2345","name":"Chalabi Boumediene","email":null,"mobile":"0665815719","message":null,"secteur":"1","hash":"57bbde66e9c7306505e657b0de1e975f","product":"CUISINIERE THOMSON GAS-GAS VENTILE NOIR \/ ECOLINE TH-CU-FS-K660B","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-25 13:24:15","admin_name_action":"farah dream","admin_id_action":"22","agent_id":"22","date":"2024-04-25 09:32:31"},
// {"id":"2243","name":"Fettal","email":null,"mobile":"0779840700","message":null,"secteur":"1","hash":"d5bc91dc230f1b8d2510d4a46ac3a02b","product":"COCOTTE MINUTE SEB PRECISION + 6L","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"17","date":"2024-04-23 14:11:30"},
// {"id":"2229","name":"Ferhat mohamed","email":null,"mobile":"0770041057","message":null,"secteur":"1","hash":"6133512c3849fad3a7aa4736840ef4ab","product":"TELEVISEUR QLED 55'' SMART SERIE 70A QA55Q70AAU","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-04-25 09:28:49","admin_name_action":"souad","admin_id_action":"11","agent_id":"11","date":"2024-04-23 12:49:37"},
// {"id":"2170","name":"meslmyakoubgmailcom","email":null,"mobile":"0555892528","message":null,"secteur":"2","hash":"de404722a4b295be046fddfe39a5a9fb","product":"Congélateur coffre, class A, 360L Blanc TH-REF-FZ-360W","category":"37","subcategory":null,"payment_methode":null,"Status_User":"8","date_action":"2024-04-23 19:50:48","admin_name_action":"nesrine kherb","admin_id_action":"17","agent_id":"17","date":"2024-04-23 08:32:24"},
// {"id":"2104","name":"Zoheir  chuchotmailfr","email":null,"mobile":"0661344394","message":null,"secteur":"1","hash":"2863884332c28bf7a9b087a3863fc8ac","product":"LAVE-VAISSELLE SAMSUNG pose libre 14 couverts Inox DW60M5070FS","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"12","date":"2024-04-22 23:00:45"},
// {"id":"2088","name":"Chericheli amine","email":null,"mobile":"0549031177","message":null,"secteur":"4","hash":"220df04547986b777cb99ce16f5baa50","product":"PLAQUE DE CUISSON BEKO - HILW64222S","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"17","date":"2024-04-22 22:16:42"},
// {"id":"2063","name":"Alioua Adel","email":null,"mobile":"0655163580","message":null,"secteur":"1","hash":"ea468e39b4bf8cbab2e6de1e08dd4039","product":"PACK Confort pour F3 (chaudière murale à gaz 24 kw + 3 radiateurs ALHIDAB avec installation )","category":"37","subcategory":null,"payment_methode":null,"Status_User":"15","date_action":"2024-04-23 11:31:29","admin_name_action":"aya guermat","admin_id_action":"23","agent_id":"23","date":"2024-04-22 21:32:35"},
// {"id":"2057","name":"Fethi cherifa","email":null,"mobile":"0671711943","message":null,"secteur":"1","hash":"62f73326b67bf58b54f73363fa6add63","product":"Chaudière murale à gaz 24 kw FACOFRI","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"17","date":"2024-04-22 20:59:00"},
// {"id":"2047","name":"Sellami nabila","email":null,"mobile":"0698812695","message":null,"secteur":"5","hash":"c10669aa03630d0c0160a8437eb559fd","product":"Four encastrable GAS\/ELT - Ventilé - Catalyseur - Afficheur - Inox \/ TFE-DGE60-IX","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-05-02 10:31:49","admin_name_action":"nesrine diardzair","admin_id_action":"25","agent_id":"13","date":"2024-04-22 20:38:11"},
// {"id":"1996","name":"MOKDAD MOHAMED","email":null,"mobile":"0541090779","message":null,"secteur":"3","hash":"8ab879799fe3040952585f7fa3a23e6e","product":"Micro-ondes 23L - Avec Grill  Blanc CONDOR CMW-M2307W","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-04-23 10:35:27","admin_name_action":"aya guermat","admin_id_action":"23","agent_id":"23","date":"2024-04-22 18:41:03"},
// {"id":"1980","name":"Abdenour fortas","email":null,"mobile":"0660913312","message":null,"secteur":"3","hash":"9bbd7ecb9b1ab151a2d926a17290b798","product":"Chauffe Eau Condor 10Lt 20Kw P3 - CCG-P310","category":"37","subcategory":null,"payment_methode":null,"Status_User":"8","date_action":"2024-04-23 10:44:42","admin_name_action":"aya guermat","admin_id_action":"23","agent_id":"23","date":"2024-04-22 18:06:38"},
// {"id":"1955","name":"Seifeddine zaak","email":null,"mobile":"0663893765","message":null,"secteur":"2","hash":"cfea40f1970bc6e07dec66dad4c78882","product":"Réfrigérateur Thomson 2 portes 480L  BLANC FN-480DDW","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-22 17:17:43"},
// {"id":"1872","name":"wafa fanit","email":null,"mobile":"0699961139","message":null,"secteur":"1","hash":"9a115088474195879f76acae54e32e2e","product":"TELEVISEUR SAMSUNG LED 32\" HD S5 SMART UA32T5300AU","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-04-25 08:43:16","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"23","date":"2024-04-22 13:36:54"},
// {"id":"1868","name":"Dib naciba","email":null,"mobile":"0675506739","message":null,"secteur":"1","hash":"0ba7c6f053e8a915b50edc709536c453","product":"Radiateur 10 éléments EL HIDHAB","category":"37","subcategory":null,"payment_methode":null,"Status_User":"14","date_action":"2024-04-23 10:35:01","admin_name_action":"farah dream","admin_id_action":"22","agent_id":"22","date":"2024-04-22 13:33:48"},
// {"id":"1857","name":"Imane benhassine","email":null,"mobile":"0696651208","message":null,"secteur":"1","hash":"77c5fb93b5c1c43cfe702a5a98bf4ee7","product":"Lave-vaisselle BEKO pose libre (15 couverts, 60 cm) - BDFN26420SQ","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-28 13:10:45","admin_name_action":"nesrine kherb","admin_id_action":"17","agent_id":"17","date":"2024-04-22 13:15:57"},
// {"id":"1856","name":"Imane benhassine","email":null,"mobile":"0696651208","message":null,"secteur":"1","hash":"0097c04b7aaf9bc0d9c685de70e8e99e","product":"Congélateur Vertical BEKO NoFrost, 320lt, 7 Tiroirs, Silver RFNE320L23S","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-04-28 13:13:00","admin_name_action":"nesrine kherb","admin_id_action":"17","agent_id":"23","date":"2024-04-22 13:10:47"},
// {"id":"1821","name":"Semache abdellah","email":null,"mobile":"0782522740","message":null,"secteur":"1","hash":"207d77ceef3bd957be31d1af9e44c40c","product":"CUISINIERE MAXWELL 60*60 inox  MAX-CKF60I","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-22 13:50:55","admin_name_action":"sabrina zouir","admin_id_action":"12","agent_id":"17","date":"2024-04-22 11:52:04"},
// {"id":"1820","name":"sabri boughrira","email":null,"mobile":"0550235063","message":null,"secteur":"4","hash":"7521dc30315e2315955edf472609cb96","product":"LAVE-LINGE SAMSUNG Front 8 Kg 1400 Tr Blanc WW80TA046TE","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-22 11:47:46"},
// {"id":"1811","name":"Yesli Abdeldjallil","email":null,"mobile":"0783309481","message":null,"secteur":"4","hash":"98a2bd9332fa984fec1a753bd8fcc892","product":"televiseur LED SAMSUNG 65'' UHD S7 SMART UA65AU7000U","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-22 11:15:29"},
// {"id":"1785","name":"Haciani","email":null,"mobile":"0770382311","message":null,"secteur":"2","hash":"9f90a1e10c33b2b46afa113aa81fc62a","product":"CUISINIERETHOMSON 60*60 INOX VENTILE GAZ - GAZ CLASSICA","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-28 13:27:14","admin_name_action":"farah dream","admin_id_action":"22","agent_id":"11","date":"2024-04-22 09:20:33"},
// {"id":"1781","name":"louahem rabah","email":null,"mobile":"0781765715","message":null,"secteur":"1","hash":"b0cbe008263e4410db5bc30b8877ad72","product":"Lave linge SAMSUNG front 9 kg 1400 tr inox addwash - ww90t554dan","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-04-25 15:48:21","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"18","date":"2024-04-22 09:17:25"},
// {"id":"1780","name":"louahem rabah","email":null,"mobile":"0781765715","message":null,"secteur":"1","hash":"650e36226ce9f2ea6adf9dae84b92422","product":"Lave Linge samsung 10.2kg Silver WD10J6410AS\/BS","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"12","date":"2024-04-22 09:15:40"},
// {"id":"1750","name":"Hadj","email":null,"mobile":"0560545022","message":null,"secteur":"2","hash":"e7ba716ec298e367ef48af1fc60148d1","product":"Plaque de cuisson Thomson 60*60 CM, Grille émaillée, Plaque émaillée \/ OMEGA \/ Noir FFD \/ HB-N60EB","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-22 06:21:55"},
// {"id":"1731","name":"Bouhadda sofiane","email":null,"mobile":"0550053543","message":null,"secteur":"1","hash":"2317bbe2a6453e4bba46281b3f19fd5f","product":"Lave linge brandt 8kg 1400 tr silver BAM84SBL","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"12","date":"2024-04-22 01:01:51"},
// {"id":"1726","name":"Zeghad hicham","email":null,"mobile":"0660336001","message":null,"secteur":"1","hash":"dafa845d66e3a14a3bec14e560c76e8f","product":"Réfrigérateur 430L NoFrost Condor Silver CRDN430S","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-22 00:42:59"},
// {"id":"1696","name":"Haddi Radia","email":null,"mobile":"0771895921","message":null,"secteur":"1","hash":"a31338e46a39308cfb416f8e5d87b765","product":"climatiseur série F 12K Btu T3_HP_","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-05-23 17:42:36","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"18","date":"2024-04-21 23:00:17"},
// {"id":"1675","name":"Daoudi Bouzid","email":null,"mobile":"0660661251","message":null,"secteur":"1","hash":"e96f81029d5495cf2e733e6ba10a9a12","product":"Congélateur 227L géant  GN-BD(W)-317L","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"26","date":"2024-04-21 22:14:31"},
// {"id":"1669","name":"CHAIBI Nacer","email":null,"mobile":"0671132241","message":null,"secteur":"5","hash":"ed2939a712c0ee45e0d902f8390f2898","product":"Hotte casquette 60 cm THD-60CLW","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-05-01 14:51:14","admin_name_action":"nesrine diardzair","admin_id_action":"25","agent_id":"18","date":"2024-04-21 21:53:32"},
// {"id":"1638","name":"batoule sahraoui","email":null,"mobile":"0798968744","message":null,"secteur":"2","hash":"50820599bcf2958d52d6d611d8f9a5be","product":"LAVE-VAISSELLE SAMSUNG pose libre 13 couverts Inox DW60M5050FS","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-04-28 08:13:02","admin_name_action":"nesrine kherb","admin_id_action":"17","agent_id":"17","date":"2024-04-21 20:26:45"},
// {"id":"1625","name":"Sayeb hicham","email":null,"mobile":"0660649244","message":null,"secteur":"1","hash":"97f3bafb3022434e9ea1d162200a2376","product":"REFRIGERATEUR BEKO 2 PORTES 510 litres Silver (Poignées extérieures)  RDSE510M21S","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-04-28 08:02:36","admin_name_action":"nesrine kherb","admin_id_action":"17","agent_id":"17","date":"2024-04-21 19:42:22"},
// {"id":"1623","name":"Alilemouis yousra","email":null,"mobile":"0555332196","message":null,"secteur":"1","hash":"b65d8eea98bf3528ab5c56a8aa283213","product":"Réfrigérateur Beko 630 lt NoFrost\/EverFresh\/Blue Light\/Prosmart InverterTM\/PVC Inox RDNE630K2VXP","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-21 19:38:03"},
// {"id":"1599","name":"Sahnine Omar","email":null,"mobile":"0667838934","message":null,"secteur":"5","hash":"a44772b92664b5d94412dc732fc818e8","product":"téléviseur led 65g5010uhd google tv gris iris","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-26 10:12:43","admin_name_action":"souad","admin_id_action":"11","agent_id":"13","date":"2024-04-21 18:35:27"},
// {"id":"1594","name":"Redouane noui","email":null,"mobile":"0675973321","message":null,"secteur":"1","hash":"5c9a5e87ef279f9e84bae43f7be49641","product":"Réfrigérateur 2 portes 520L  Blanc No Frost FN-520DDW","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-04-24 15:35:28","admin_name_action":"nesrine kherb","admin_id_action":"17","agent_id":"17","date":"2024-04-21 18:28:27"},
// {"id":"1592","name":"Ahmed el hadj hichem","email":null,"mobile":"0675843708","message":null,"secteur":"1","hash":"5f1853036ba8156b46729aa7d54651fe","product":"Plaque de cuisson Thomson 60*60 CM, Grille émaillée \/ Surface inox  OMEGA \/ HB-N60IN","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-05-08 16:23:55","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"18","date":"2024-04-21 18:24:36"},
// {"id":"1576","name":"BOUBAKAR Atmane","email":null,"mobile":"0676997940","message":null,"secteur":"1","hash":"5e8d4d40815dc8c40ee70fc65c2e5db9","product":"téléviseur led 58g5010uhd google tv gris iris","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-21 17:42:18"},
// {"id":"1573","name":"Charef mohammed","email":null,"mobile":"0660072986","message":null,"secteur":"1","hash":"2b0d17a9fd5603b029d9c61239502590","product":"FOUR ENCASTRABLE THOMSON GAS\/GAS VENTILLE CATALYSEUR AFFICHEUR \/ INOX \/  TFE-OGG60-IX","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-05-08 16:28:28","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"18","date":"2024-04-21 17:26:58"},
// {"id":"1568","name":"AHCENE AOUETA","email":null,"mobile":"0661603011","message":null,"secteur":"1","hash":"035c43f4cf98a1bd63bdf75df2a49d02","product":"Congélateur THOMSON Coffre, Class A, 290L Blanc","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-21 17:13:10"},
// {"id":"1563","name":"Meddah fatma zohra","email":null,"mobile":"0670285308","message":null,"secteur":"1","hash":"e0f97ae8c1fc2966472cdeb697e20119","product":"Hotte casquette 60 cm THD-60CLB","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-25 09:55:46","admin_name_action":"feriel kargas","admin_id_action":"24","agent_id":"11","date":"2024-04-21 17:07:23"},
// {"id":"1562","name":"Hadli samah","email":null,"mobile":"0660537867","message":null,"secteur":"1","hash":"9d8ac2e14e508208af6611be1a6a47ec","product":"Lave-vaisselle Beko pose libre (16 couverts, 60 cm) - BDFN36640G","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-21 17:06:57"},
// {"id":"1539","name":"Kerkour amine","email":null,"mobile":"0697083118","message":null,"secteur":"1","hash":"3e7c83b7146b925ee79940a084c7483f","product":"Hotte casquette 60 cm THD-60CLX","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-04-25 10:03:03","admin_name_action":"feriel kargas","admin_id_action":"24","agent_id":"11","date":"2024-04-21 16:15:14"},
// {"id":"1538","name":"Kerkour amine","email":null,"mobile":"0697083118","message":null,"secteur":"1","hash":"006fe00b62467a29a4b59b496ee98eae","product":"TELEVISEUR SAMSUNG LED 43\" FHD S5 SMART UA43T5300AU","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-05-15 09:26:24","admin_name_action":"farah dream","admin_id_action":"22","agent_id":"12","date":"2024-04-21 16:13:48"},
// {"id":"1537","name":"Kerkour amine","email":null,"mobile":"0697083118","message":null,"secteur":"1","hash":"ee3b4b7a44a081b46086c48305d1e247","product":"Lave-linge front 10,5kg 1400 trbldc brandt BAM104QVWBL","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"13","date":"2024-04-21 16:11:55"},
// {"id":"1524","name":"Nafa hafida","email":null,"mobile":"0797401249","message":null,"secteur":"1","hash":"4159551db8c027ad6975167235352303","product":"Cuisiniere 4Fx 60Cm Cristor Rossa Gris ROS464G","category":"37","subcategory":null,"payment_methode":null,"Status_User":"10","date_action":"2024-08-04 21:25:23","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"12","date":"2024-04-21 15:31:50"},
// {"id":"1522","name":"Chiboub fellah Mohamed Mokhtar","email":null,"mobile":"0554350351","message":null,"secteur":"2","hash":"0c8d50897816e335aa27b386197c591c","product":"TELEVISEUR SAMSUNG LED 50\" UHD S7 SMART UA50AU7000U","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-25 10:12:53","admin_name_action":"feriel kargas","admin_id_action":"24","agent_id":"11","date":"2024-04-21 15:30:39"},
// {"id":"1510","name":"Khatkhat MOHAMMED","email":null,"mobile":"0663252566","message":null,"secteur":"1","hash":"0a0a5b1a99056f809542b0a0717eea23","product":"Réfrigérateur Thomson 2 portes 480L inox FN-480DDS","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-24 14:05:42","admin_name_action":"nesrine kherb","admin_id_action":"17","agent_id":"17","date":"2024-04-21 15:07:43"},
// {"id":"1508","name":"Bellal bachir","email":null,"mobile":"0671480041","message":null,"secteur":"1","hash":"6037fd95daea208496d8ce956c345a54","product":"LAVE-LINGE SAMSUNG Top 7 Kg 700 Tr Blanc WA70H4000SW","category":"37","subcategory":null,"payment_methode":null,"Status_User":"10","date_action":"2024-08-04 21:10:55","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"11","date":"2024-04-21 15:04:02"},
// {"id":"1505","name":"Akroum Zineb","email":null,"mobile":"0668483242","message":null,"secteur":"1","hash":"08ed03e87b48bdd519043ab5e2cbdcb5","product":"Réfrigérateur brandt 2 porte _ 600l no frost BD6010NX","category":"37","subcategory":null,"payment_methode":null,"Status_User":"4","date_action":"2024-04-24 11:17:08","admin_name_action":"nesrine kherb","admin_id_action":"17","agent_id":"17","date":"2024-04-21 14:51:53"},
// {"id":"1504","name":"Mahdji mohamed","email":null,"mobile":"0667049729","message":null,"secteur":"3","hash":"2909f00e3da819fb716590586ff198de","product":"Téléviseur SAMSUNG SMART 43\" \/ LED \/ Full HD \/ UA43T5300AUCBM","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"18","date":"2024-04-21 14:51:52"},
// {"id":"1490","name":"Akachat derradji","email":null,"mobile":"0550762657","message":null,"secteur":"1","hash":"9ab98c7759b5d1ea23c434dfa2b15669","product":"téléviseur led 55g5010uhd google tv gris iris","category":"37","subcategory":null,"payment_methode":null,"Status_User":"1","date_action":"2024-04-24 11:06:27","admin_name_action":"nesrine kherb","admin_id_action":"17","agent_id":"17","date":"2024-04-21 14:23:58"},
// {"id":"1487","name":"Ben souileh Mohamed","email":null,"mobile":"0670276595","message":null,"secteur":"1","hash":"b22190886f584e35905de66ce7c25da4","product":"REFRIGERATEUR BRANDT BD5010NX 500L INOX","category":"37","subcategory":null,"payment_methode":null,"Status_User":"10","date_action":"2024-08-04 21:04:26","admin_name_action":"amina selmaoui","admin_id_action":"18","agent_id":"12","date":"2024-04-21 14:19:14"},
// {"id":"1482","name":"Aous Brahim","email":null,"mobile":"0660991085","message":null,"secteur":"5","hash":"1b2303952a4c73ba157142d28ce563f7","product":"climatiseur série F 12K Btu T3_HP_ON\/OFF brandt","category":"37","subcategory":null,"payment_methode":null,"Status_User":"9","date_action":"2024-05-01 14:03:08","admin_name_action":"nesrine diardzair","admin_id_action":"25","agent_id":"13","date":"2024-04-21 14:03:44"},
// {"id":"1481","name":"Amis Lounis","email":null,"mobile":"0662679035","message":null,"secteur":"2","hash":"24c19282e3bd3b92e6feebb04cabbd71","product":"Réfrigérateur 2 portes 520L Inox No Frost FN-520DDS","category":"37","subcategory":null,"payment_methode":null,"Status_User":"0","date_action":null,"admin_name_action":null,"admin_id_action":null,"agent_id":"18","date":"2024-04-21 14:01:52"}

//         ]

        
        
//         for (let index = 0; index < Producte.length; index++) {
//             fetchData(Producte[index]?.hash)
            
//             // console.log(query);
//         }

    
      
//     }, [])
    


    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Commandes<span>Diar Dzair</span></div>

                    <div className='store-search-section'>
                        <div>
                        <label style={{ fontSize: '13px' }}>Nom</label>
                            <input onKeyDown={handleKeyDown} type="text" placeholder={"Nom"} onChange={(e) => setWord(e.target.value)} value={Word} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                        <label style={{ fontSize: '13px' }}>Mobile</label>
                            <input onKeyDown={handleKeyDown} type="tel" placeholder={"Mobile"} onChange={(e) => setTel(e.target.value)} value={Tel} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                        <label style={{ fontSize: '13px' }}>Date</label>
                            <input onKeyDown={handleKeyDown} type="date" onChange={(e) => setDate_start(e.target.value)} value={Date_start} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                        <label style={{ fontSize: '13px' }}>Mode de paiement</label>
                            <select id="inputState" style={{ minWidth: '190px' }} className="form-select me-3" onChange={(e) => setModePayment(e.target.value)}>
                                <option className='custum-opt' selected={"selected"} disabled value="vide">Mode de paiement</option>
                                <option selected={ModePayment == 'all'} value={'all'}>Tous</option>
                                <option selected={ModePayment == '1'} value={'1'}>CCP</option>
                                <option selected={ModePayment == '2'} value={'2'}>Banque</option>
                            </select>
                        </div>
                        <div>
                        <label style={{ fontSize: '13px' }}>Catégories</label>
                            <select id="inputState" className="form-select me-3" onChange={(e) => setValidvendeur(e.target.value)}>
                                <option className='custum-opt' selected={"selected"} disabled value="vide">Catégories</option>
                                <option selected={Validvendeur == 'all'} value={'all'}>Tous</option>
                                <option selected={Validvendeur == '119'} value={'119'}>Moto</option>
                                <option selected={Validvendeur == '37'} value={'37'}>Électroménager</option>
                            </select>
                        </div>
                        {SousCategoriesList?.length > 0 ? 
                        
                    
                        <div>
                            <label style={{ fontSize: '13px' }}>Sous-Catégories</label>
                            <select id="inputState" className="form-select me-3" onChange={(e) => setSubCategory(e.target.value)}>
                                <option className='custum-opt' selected={"selected"} disabled value="vide">Sous-Catégories</option>
                                <option selected={SubCategory == 'all'} value={'all'}>Tous Les Sous Catégories</option>
                                {SousCategoriesList?.length > 0 && SousCategoriesList?.map((item, i) => (
                                    <option selected={SubCategory == item?.id} key={`SubCategory${item?.unicnom}`} value={item?.id}>{item?.nom}</option>
                                ))
                                }
                            </select>
                        </div>
                    : ''}

                        <div>
                        <label style={{ fontSize: '13px' }}>Secteur d’activité</label>
                            <select className="form-select me-3" aria-label="Default select example" onChange={(e) => setsecteuActivité(e.target.value)}>
                                <option className='custum-opt' selected={"selected"} disabled value="vide">Secteur d’activité</option>

                                <option selected={secteuActivité === 'all'} value={'all'}>Tous</option>
                                {secteuActivitéList.length > 0 && secteuActivitéList.map((item, i) => (

                                    <option selected={secteuActivité == item?.value} value={item?.value}>{item?.[Lang]}</option>

                                ))
                                }

                            </select>
                        </div>
                        {/* <div>
                            <select id="inputState" class="form-select me-3" onChange={(e) => setWilaya(e.target.value)}>
                                <option value={'all'} selected={Wilaya == "all" ? "selected" : ''}>Tous Les Wilayas</option>
                                {WilayaList?.length > 0 && WilayaList?.map((item, i) => (
                                    <option selected={Wilaya == item?.value} value={item?.value}>{item?.text}</option>
                                ))
                                }
                            </select>
                        </div> */}
                        <div>
                        <label style={{ fontSize: '13px' }}>Status</label>
                            <select id="inputState" class="form-select me-3" onChange={(e) => setStatusType(e.target.value)}>
                                <option disabled selected={'selected'} value='vide'>Status</option>
                                <option selected={StatusType === 'all'} value={'all'}>Tous</option>

                                {Object?.entries(ActionsFiltred).map(([service, options]) => (
                                    <optgroup key={service} label={service}>
                                        {options?.map(item => (
                                            <option key={item?.id} selected={StatusType == item?.id} value={item?.id}>{item?.name}</option>
                                        ))}
                                    </optgroup>
                                ))}
                                {/* <optgroup label="Niveau 1 (centre d'appel)">
                                {Statutdefinancement_?.length > 0 && Statutdefinancement_?.map((item, i) => (
                                     i < 11 ?
                                    <option selected={StatusType == item?.id} value={item?.id}>{item?.name}</option> 
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Niveau 2 (relation client)">
                                {Statutdefinancement_?.length > 0 && Statutdefinancement_?.map((item, i) => (
                                    i >= 11 && i < 20 ?
                                    <option selected={StatusType == item?.id} value={item?.id}>{item?.name}</option> 
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Niveau 3 (relation banque)">
                                {Statutdefinancement_?.length > 0 && Statutdefinancement_?.map((item, i) => (
                                    i >= 20 && i < 25 ?
                                    <option selected={StatusType == item?.id} value={item?.id}>{item?.name}</option> 
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Commercial">
                                {Statutdefinancement_?.length > 0 && Statutdefinancement_?.map((item, i) => (
                                    i >= 25 && i < 27 ?
                                    <option selected={StatusType == item?.id} value={item?.id}>{item?.name}</option> 
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Ramassage">
                                {Statutdefinancement_?.length > 0 && Statutdefinancement_?.map((item, i) => (
                                    i >= 27 && i < 33 ?
                                    <option selected={StatusType == item?.id} value={item?.id}>{item?.name}</option> 
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Livraison">
                                {Statutdefinancement_?.length > 0 && Statutdefinancement_?.map((item, i) => (
                                    i >= 33 && i < 35 ?
                                    <option selected={StatusType == item?.id} value={item?.id}>{item?.name}</option> 
                                    : ''
                                    ))
                                }
                                </optgroup> */}
                            </select>
                            {/* <div className='alert-form'>{Showerr && (Typeactnaissnce == "" ? (infoperssonnelle['msgerr15'][Lang]) : '')}</div> */}
                        </div>

                        <div className='me-3'>
                        <label style={{ fontSize: '13px' }}>Les Agents</label>
                            <select style={{ minWidth: '180px' }} id="inputState" class="form-select" onChange={(e) => setCategorie(e.target.value)}>
                                <option value={'all'} selected={Categorie == "all" ? "selected" : ''}>Tous les agents</option>
                                {/* <option selected={"selected"} disabled value="">Catégories</option> */}
                                {Category?.length > 0 && Category?.map((item, i) => (
                                    <option selected={item?.id == Categorie ? 'selected' : ''} value={item?.id}>
                                        <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 51.408 78.989">
                                            <path fill={item?.color} id="label-svgrepo-com" d="M97.747,0H57.014a5.337,5.337,0,0,0-5.337,5.337V73.651a5.337,5.337,0,0,0,8.986,3.895L77.381,61.884,94.1,77.546a5.337,5.337,0,0,0,8.986-3.895V5.337A5.338,5.338,0,0,0,97.747,0Z" transform="translate(-51.677)" />
                                        </svg>
                                        {item?.name}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className='search-btn-dashboard' onClick={SearchQUERY}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.621" height="30.621" viewBox="0 0 30.621 30.621">
                                <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(1.5 1.5)">
                                    <path id="Tracé_1" data-name="Tracé 1" d="M28.5,16.5a12,12,0,1,1-12-12,12,12,0,0,1,12,12Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <path id="Tracé_2" data-name="Tracé 2" d="M31.5,31.5l-6.525-6.525" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                </g>
                            </svg>


                        </div>

                    </div>

                    <div className="mt-3 text-center"><b> Total : {TotalData || 0} </b></div>

                    <div className='dashboard-table mt-3'>
                        <table>

                            <thead>
                                <tr>
                                    <th scope="col">Produits</th>
                                    <th scope="col">Nom</th>
                                    {/* <th className='d-xl-block d-none' scope="col">email</th> */}
                                    {/* <th scope="col">Email</th> */}
                                    <th scope="col">Mobile</th>
                                    <th scope="col">Secteur d’activité</th>
                                    <th scope="col">Mode de Paiement</th>
                                    <th scope="col">Banque</th>



                                    <th scope="col">Status</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">👩🏻 Agent</th>



                                    {/* <th scope="col">Valid</th> */}
                                    <th scope="col"></th>

                                </tr>
                            </thead>
                            {!Loading && <tbody>

                                {ListVentes?.length > 0 && ListVentes?.map((item, i) => (

                                    <tr>
                                        <td data-label="Produits">
                                            {/* <img src={`${process.env.REACT_APP_LOCAL_URL}/images/${item?.image1}`} /> */}
                                            <a style={{ color: "black" }} href={`https://new.diardzair.com.dz/product/${item?.hash}`} target="blanc" >  <div>{item?.product}</div> </a>
                                        </td>
                                        <td data-label="Etat">{item?.name}</td>
                                        {/* <td className='d-xl-block d-none' data-label="email">{item?.email_parrain}</td> */}
                                        {/* <td data-label="Email">{item?.email}</td> */}
                                        <td data-label="Mobile">{item?.mobile}</td>
                                        <td data-label="Secteur d’activité">{onlyNumbers(item?.secteur) ? FindsecteurPerId(item?.secteur) : '-'} </td>
                                        <td data-label="Mode de Paiement">{item?.payment_methode ? item?.payment_methode == "1" ? 'CCP' : 'Banque' : '-'} </td>
                                        <td data-label="Banque">{item?.bank ? item?.bank == "1" ? <img src="/img/alsalam.jpg" /> : <img src="/img/bna.jpg" /> : '-'} </td>

                                        <td data-label="Status"><div className='status-user'>{onlyNumbers(item?.Status_User) ? FindActionPerId(item?.Status_User) : ''}</div></td>
                                        <td data-label="Date">{item?.date?.slice(0, -3) || '-'}</td>
                                        <td data-label="Agent"><div className='agent-'>👩🏻 {item?.agent_name || '-'}</div></td>

                                        {/* <td data-label="Valid">
                                            <a onClick={() => validVendeur(item?.id_vendeur, item?.active == 1 ? 0 : 1)} >
                                               
                                                <div>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={(e) => console.log('')} checked={item?.active == 1 ? true : false} />
                                                        <span className="slider round"><p className={item?.active == 1 ? 'checked-p' : 'unchecked-p'}>{item?.active == 1 ? 'Oui' : 'Non'}</p> </span>
                                                    </label>
                                                </div>
                                              
                                            </a>


                                        </td> */}
                                        <td className="vendeursadminlist" data-label=" ">
                                            {
                                                item?.iduser != null ?
                                                    <a href={`/updateadmin/${item?.iduser}?role=user`}> <div className='account-store-btn my-1  mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="27.83" height="20.59" viewBox="0 0 27.83 20.59">
                                                        <g id="account-management-svgrepo-com" transform="translate(-8 -179.2)">
                                                            <path id="Tracé_9274" data-name="Tracé 9274" d="M10.834,199.79A2.893,2.893,0,0,1,8,196.845v-14.7a2.893,2.893,0,0,1,2.834-2.945H33a2.893,2.893,0,0,1,2.834,2.945v14.679A2.893,2.893,0,0,1,33,199.768H10.834Zm0-19.416a1.735,1.735,0,0,0-1.7,1.771v14.679a1.749,1.749,0,0,0,1.7,1.771H33a1.735,1.735,0,0,0,1.7-1.771V182.145a1.749,1.749,0,0,0-1.7-1.771Z" transform="translate(0)" fill="#fff" />
                                                            <path id="Tracé_9275" data-name="Tracé 9275" d="M48.139,339.105a.665.665,0,0,1,0-1.328h9.077a.665.665,0,0,1,0,1.328Zm0,3.3a.665.665,0,0,1,0-1.328h9.077a.665.665,0,0,1,0,1.328Zm0,3.52a.665.665,0,0,1,0-1.328H55.8a.665.665,0,0,1,0,1.328Zm-13.372,6.863a.613.613,0,0,1-.2-.022.711.711,0,0,1-.443-.886,7.846,7.846,0,0,1,4.1-4.871l.443-.221-.376-.332a5.09,5.09,0,0,1-1.749-3.852,4.808,4.808,0,1,1,9.609,0,5.09,5.09,0,0,1-1.749,3.852l-.376.332.443.221a9.176,9.176,0,0,1,4.362,5.38c.443,1.461-.465,1-.044.443-.089.133-.465-.155-.886-.155-.354,0-.531.686-.531.62-.863-2.878-3.343-5.624-6.022-5.624a6.292,6.292,0,0,0-5.978,4.605.6.6,0,0,1-.6.509Zm6.62-13.793a3.611,3.611,0,1,0,3.5,3.609A3.559,3.559,0,0,0,41.387,338.995Z" transform="translate(-25.372 -154.016)" fill="#fff" />
                                                        </g>
                                                    </svg>
                                                    </div></a>
                                                    :
                                                    // <a href={`/linkadminvendeur/${item?.id_vendeur}`}> <div style={{ background: "linear-gradient(45deg, #e252ea, #3b6ab7)" }} className='account-store-btn my-1  mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="27.83" height="20.59" viewBox="0 0 27.83 20.59">
                                                    //     <g id="account-management-svgrepo-com" transform="translate(-8 -179.2)">
                                                    //         <path id="Tracé_9274" data-name="Tracé 9274" d="M10.834,199.79A2.893,2.893,0,0,1,8,196.845v-14.7a2.893,2.893,0,0,1,2.834-2.945H33a2.893,2.893,0,0,1,2.834,2.945v14.679A2.893,2.893,0,0,1,33,199.768H10.834Zm0-19.416a1.735,1.735,0,0,0-1.7,1.771v14.679a1.749,1.749,0,0,0,1.7,1.771H33a1.735,1.735,0,0,0,1.7-1.771V182.145a1.749,1.749,0,0,0-1.7-1.771Z" transform="translate(0)" fill="#fff" />
                                                    //         <path id="Tracé_9275" data-name="Tracé 9275" d="M48.139,339.105a.665.665,0,0,1,0-1.328h9.077a.665.665,0,0,1,0,1.328Zm0,3.3a.665.665,0,0,1,0-1.328h9.077a.665.665,0,0,1,0,1.328Zm0,3.52a.665.665,0,0,1,0-1.328H55.8a.665.665,0,0,1,0,1.328Zm-13.372,6.863a.613.613,0,0,1-.2-.022.711.711,0,0,1-.443-.886,7.846,7.846,0,0,1,4.1-4.871l.443-.221-.376-.332a5.09,5.09,0,0,1-1.749-3.852,4.808,4.808,0,1,1,9.609,0,5.09,5.09,0,0,1-1.749,3.852l-.376.332.443.221a9.176,9.176,0,0,1,4.362,5.38c.443,1.461-.465,1-.044.443-.089.133-.465-.155-.886-.155-.354,0-.531.686-.531.62-.863-2.878-3.343-5.624-6.022-5.624a6.292,6.292,0,0,0-5.978,4.605.6.6,0,0,1-.6.509Zm6.62-13.793a3.611,3.611,0,1,0,3.5,3.609A3.559,3.559,0,0,0,41.387,338.995Z" transform="translate(-25.372 -154.016)" fill="#fff" />
                                                    //     </g>
                                                    // </svg>
                                                    // </div></a>
                                                    ''

                                            }
                                            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/clientaction/' + item?.id)}> <div className='mx-1'>
                                                <img src="https://cdn-icons-png.flaticon.com/256/6811/6811275.png" width={"46"} />

                                            </div></div>
                                            <a href={`/contact-requests/${item?.id}`}> <div className='voir-btn my-1  mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                    </g>
                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                    </g>
                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </svg>
                                            </div></a>

                                            <a href={`/updatecommande/${item?.id}`}> <div className='update-btn mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="21.167" height="20.59" viewBox="0 0 21.167 20.59">
                                                <g id="edit-246" transform="translate(-134.2 -129)">
                                                    <path id="Tracé_9269" data-name="Tracé 9269" d="M152.96,169.464a4,4,0,0,1-3.993,3.993h-9.63a4,4,0,0,1-3.993-3.993v-9.628a4,4,0,0,1,3.993-3.993h3.478V154.7h-3.478a5.143,5.143,0,0,0-5.137,5.137v9.628a5.143,5.143,0,0,0,5.137,5.137h9.628a5.143,5.143,0,0,0,5.137-5.137v-3.478h-1.144v3.478Z" transform="translate(0 -25.011)" fill="#fff" />
                                                    <path id="Tracé_9270" data-name="Tracé 9270" d="M278.443,129.922a3.15,3.15,0,0,0-4.453,0l-11.026,11.026a2.943,2.943,0,0,0-.836,1.675l-.509,3.558a.5.5,0,0,0,.493.571.66.66,0,0,0,.072-.005l3.558-.509a2.959,2.959,0,0,0,1.675-.836l11.026-11.026A3.149,3.149,0,0,0,278.443,129.922ZM264.3,141.227l9.333-9.333,2.832,2.835-9.33,9.333Zm1.278,3.877-2.709.386.386-2.709a1.835,1.835,0,0,1,.265-.715l2.771,2.771A1.747,1.747,0,0,1,265.581,145.1Zm12.053-11.541-.356.356-2.832-2.835.356-.356a2,2,0,1,1,2.832,2.835Z" transform="translate(-123.999)" fill="#fff" />
                                                </g>
                                            </svg>
                                            </div></a>


                                            {/* <a href={`/updatevendeur/${item?.id_vendeur}`}> <div className='update-btn mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="21.167" height="20.59" viewBox="0 0 21.167 20.59">
                                                <g id="edit-246" transform="translate(-134.2 -129)">
                                                    <path id="Tracé_9269" data-name="Tracé 9269" d="M152.96,169.464a4,4,0,0,1-3.993,3.993h-9.63a4,4,0,0,1-3.993-3.993v-9.628a4,4,0,0,1,3.993-3.993h3.478V154.7h-3.478a5.143,5.143,0,0,0-5.137,5.137v9.628a5.143,5.143,0,0,0,5.137,5.137h9.628a5.143,5.143,0,0,0,5.137-5.137v-3.478h-1.144v3.478Z" transform="translate(0 -25.011)" fill="#fff" />
                                                    <path id="Tracé_9270" data-name="Tracé 9270" d="M278.443,129.922a3.15,3.15,0,0,0-4.453,0l-11.026,11.026a2.943,2.943,0,0,0-.836,1.675l-.509,3.558a.5.5,0,0,0,.493.571.66.66,0,0,0,.072-.005l3.558-.509a2.959,2.959,0,0,0,1.675-.836l11.026-11.026A3.149,3.149,0,0,0,278.443,129.922ZM264.3,141.227l9.333-9.333,2.832,2.835-9.33,9.333Zm1.278,3.877-2.709.386.386-2.709a1.835,1.835,0,0,1,.265-.715l2.771,2.771A1.747,1.747,0,0,1,265.581,145.1Zm12.053-11.541-.356.356-2.832-2.835.356-.356a2,2,0,1,1,2.832,2.835Z" transform="translate(-123.999)" fill="#fff" />
                                                </g>
                                            </svg>
                                            </div></a> */}

                                            <a onClick={() => removeVendeur(item?.id)} > <div className='delete-btn my-1 '><svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                            </svg>
                                            </div></a>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>}
                        </table>
                        {Loading &&
                            <LoadData />
                        }

                        {
                            ListVentes?.length === 0 || !ListVentes && !Loading &&
                            <div className='no-results'>Il n'y a pas de données</div>

                        }

                    </div>
                    <div>
                        {PageNumber > 1 &&
                            <div class="text-center mt-5 pt-1 mb-5">
                                <div class={`pagination ${Loading && 'd-none'} ${CurrentPage} ${PageNumber} ${CurrentPage < PageNumber}`}>
                                    {CurrentPage > 1 ? <a href={`?page=1${UrlPagination}`}  >{'<<'}</a> : <a>{'<<'}</a>}

                                    {CurrentPage !== 1 ? <a href={`?page=${parseInt(CurrentPage) - 1}${UrlPagination}`}>&laquo;</a> : <a>&laquo;</a>}



                                    {PageNumber > 0 && [...Array(PageNumber).keys()].map((item, i) => (

                                        <>
                                            {(item + 1 == (parseInt(CurrentPage) + 1) || item + 1 == (parseInt(CurrentPage) + 2) || item + 1 == (parseInt(CurrentPage) - 1) || item + 1 == (parseInt(CurrentPage) - 2) || item + 1 == CurrentPage) ?
                                                <a href={`?page=${item + 1}${UrlPagination}`} className={item + 1 == CurrentPage ? 'active' : ''}  >{item + 1}</a>
                                                : ''}
                                        </>

                                    ))}



                                    {CurrentPage < PageNumber ? <a href={`?page=${parseInt(CurrentPage) + 1}${UrlPagination}`}>&raquo;</a> : <a>&raquo;</a>}

                                    {CurrentPage < PageNumber ? <a href={`?page=${PageNumber}${UrlPagination}`}  >{'>>'}</a> : <a>{'>>'}</a>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ContactRequests